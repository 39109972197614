<template>
  <div>
    <!-- 汇报列表头部 -->
    <van-sticky :offset-top="0">
      <van-nav-bar title="汇报管理" left-text left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <div class="list_box">
      <div class="list_search">
        <form action="#" style="width:90%">
          <van-search
            v-model="search_value"
            placeholder="请输入搜索汇报人"
            @search="onSearch"
            @cancel="onCancel"
          />
          <!-- <i>筛选</i> -->
        </form>
      </div>
      <ul class="infinite-list" :infinite-scroll-immediate="false" v-infinite-scroll="load" style="overflow:auto;height: 600px;">
        <li
          class="report_list infinite-list-item"
          v-for="(item,index) in reportList"
          :key="index"
          @click="go_info(item)"
        >
          <div>
            <el-avatar icon="el-icon-user-solid" :src="userSite + item.avatar"></el-avatar>
          </div>
          <div>
            <p class="list_title">{{item.title}}</p>
            <span
              class="list_time"
            >{{item.position}} &nbsp;&nbsp;&nbsp;{{item.create_time | fmtdate}}</span>
          </div>
        </li>
      </ul>
    </div>
    <!-- 新增按钮 -->
    <van-button type="info" @click="report_add()">
      <i class="el-icon-plus"></i>
    </van-button>
    <!-- 弹出层 -->
    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      close-icon-position="bottom-left"
      round
      position="bottom"
    >
      <div class="popup_box">
        <div
          class="popup_s_box"
          v-for="(item,index) in popupList"
          :key="index"
          @click="reportAdd(item)"
        >
          <img :src="require('../../assets/images/' + item.img + '.png')" alt />
          <span>{{item.span}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import user from '../../components/global.vue'
export default {
  data () {
    return {
      listParams: {
        page: 1,
        limit: 10,
        keywords: "",
      },
      show: false,
      userSite: user.userSite,
      search_value: '',
      reportList: [],//汇报列表
      loading: false,
      finished: false,
      refreshing: false,
      popupList: [
        {
          img: 'date_icon',
          span: '日报',
          type: '1',
        },
        {
          img: 'moth_icon',
          span: '周报',
          type: '2'
        }, {
          img: 'year_ban_icon',
          span: '月报',
          type: '3'
        }, {
          img: 'year_icon',
          span: '年报',
          type: ''
        }
      ],
    }
  },
  created () {
    this.getReportList();
  },
  methods: {
    load() {
      console.log(123)
      this.listParams.page ++
      this.axios.get('/api/report/list', { params: { ...this.listParams } }).then((res) => {
        if(res.data.data.length > 0){
          this.reportList.push(res.data.data)
        }else{
          Toast('没有更多了')
          console.log(this.reportList,888)
        }
      })
    },
    onSearch (val) {
      console.log(val, this.search_value)
      this.axios.get('/api/report/list', { params: { ...this.listParams,keywords:val,page:1 } }).then((res) => {
        if(res.data.data > 0){
          this.reportList = res.data.data
        }else{
          this.reportList = res.data.data
          Toast('搜索结果不存在')
        }
      })
    },
    onCancel () {
      Toast('取消');
    },
    reportAdd (item) {
      console.log(item, 'item')
      this.$router.push({ path: '/phone/report/add', query: { item: item } })
    },
    report_add () {
      this.show = true
    },
    getReportList () {
      this.axios.get('/api/report/list', { params: { ...this.listParams } }).then((res) => {
        this.reportList = res.data.data
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    go_info (item) {
      this.$router.push({ path: '/phone/report/detail', query: { item: item } })
    },
  },

}
</script>

<style scoped>
div/deep/.van-nav-bar .van-icon {
  color: #000;
}
div/deep/.el-avatar {
  width: 54px;
  height: 54px;
  margin-right: 12px;
}
div/deep/.el-avatar--icon {
  font-size: 28px;
  line-height: 54px;
}
div/deep/.van-search {
  width: 90%;
  padding: 0;
  margin: 20px 0;
}
div/deep/.van-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 70px;
  left: 77%;
  /* transform: translateX(-50%); */
}
.list_search {
  display: flex;
  align-items: center;
  border: none;
  background: #fff;
  color: #000000;
}
.list_search i {
  margin-left: 5px;
}
.list_box {
  margin: 0 20px;
}
.report_list {
  height: 54px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.list_title {
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #000000;
  margin: 0;
}
.list_time {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #a1a1a1;
}
.el-icon-plus {
  font-size: 30px;
}
div/deep/.van-popup__close-icon {
  left: 50%;
  transform: translateX(-50%);
  color: #404040;
}
.popup_box {
  width: 90%;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-top: 50px;
}
.popup_s_box {
  width: 33%;
  text-align: center;
  margin-bottom: 25px;
}
.popup_s_box img {
  display: block;
  width: 48px;
  margin: 0 auto;
}
.popup_s_box span {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #000000;
}
</style>